<template>
  <div id="loginSection" class="align">
    <loader v-show="isLoading"></loader>
    <div class="contentBlock" v-show="!isLoading">
      <div class="title">
        <h3>На ваш номер выслан SMS-код</h3>
      </div>

      <div class="body">
        <div class="inputBlock">
          <input type="tel" id="ConfirmationCode"
                 v-model.trim="ConfirmationCode"
                 v-on:blur="checkConfirmationCode">
          <label for="ConfirmationCode" v-bind:class="{showLabel: ConfirmationCode.length === 0}">SMS-код</label>
          <div v-show="errorConfirmationCode.length > 0" v-html="errorConfirmationCode" class="errMsg"></div>
        </div>

        <ul class="stxt">
          <li>Требования к сложности пароля:</li>
          <li v-bind:style="{color:passLength ? 'green':'red'}">1. Минимальная длинна пароля 8 символов</li>
          <li v-bind:style="{color:passUpperCase ? 'green':'red'}">2. Включать прописные символы</li>
          <li v-bind:style="{color:passSmallCase ? 'green':'red'}">3. Включать cтрочные символы</li>
          <li v-bind:style="{color:passNumber ? 'green':'red'}">4. Включать десятичные цифры</li>
        </ul>

        <div class="inputBlock">
          <input type="password" id="pass1"
                 v-model.trim="pass1"
                 v-on:blur="checkNewPass">
          <label for="pass1" v-bind:class="{showLabel: pass1.length === 0}">Новый пароль</label>
          <div v-show="errorPassword.length > 0" v-html="errorPassword" class="errMsg"></div>
        </div>

        <div class="inputBlock">
          <input type="password" v-model.trim="pass2" id="pass2" v-on:blur="checkPassConfirm">
          <label for="pass2" v-bind:class="{showLabel: pass2.length === 0}">Подтвердите новый пароль</label>
          <div v-show="errorPassword2.length > 0" v-html="errorPassword2" class="errMsg"></div>
        </div>

        <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>

      </div>

      <div class="contentFooter btnIsset">

        <p class="stxt" v-if="smsCountdown > 0">Повторная отправка смс через {{smsCountdown}} секунд.</p>
        <div v-else>
          <p class="linkColor" v-on:click="showSmsDesc = true">SMS-код долго не приходит?</p>
          <ul class="stxt" v-if="showSmsDesc">
            <li>Если возникают проблемы с доставкой SMS-сообщений:</li>
            <li>- Отправьте себе SMS с другого телефона и посмотрите доставляется ли оно.</li>
            <li>- Если сообщение доставляется, попробуйте запросить SMS с кодом подтверждения повторно.</li>
            <li>- Если сообщение не приходит - значит могут наблюдаться проблемы у мобильного оператора или переполнена память телефона.</li>
          </ul>

          <router-link :class="'linkColor'" to="/login/nsb/recovery" v-if="isNsb">Отправить SMS-код
            повторно</router-link>
          <router-link :class="'linkColor'" to="/login/recovery" v-else>Отправить SMS-код повторно</router-link>
        </div>

        <button v-on:click="sendAction">Далее</button>
      </div>


    </div>

  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "ConfirmationCode",
    data() {
      return {
        ConfirmationCode: '',
        pass1:'',
        pass2: '',
        errorPassword: '',
        errorPassword2: '',
        errorConfirmationCode: '',
        responseError: '',
        isLoading: false,
        smsCountdown: 60,
        showSmsDesc:false,
      }
    },
    methods:{
      checkConfirmationCode() {
        //проверка кода подтверждения
        if (this.ConfirmationCode.length == 0) {
          this.errorConfirmationCode = '<p>Обязательное поле</p>';
          return false;
        }
        if (!this.$bikeFunction.isNumber(this.ConfirmationCode)) {
          this.errorConfirmationCode = '<p>Недопустимые символы в коде подтверждения</p>';
          return false;
        }
        this.errorConfirmationCode = '';
        return true;
      },
      checkPassConfirm() {
        //проверяем пароли на совпадение
        if (this.pass1 !== this.pass2) {
          this.errorPassword2 = '<p>Пароли не совпадают.</p>';
          return false
        } else {
          this.errorPassword2 = '';
        }
        return true;
      },
      checkNewPass() {
        if (!(this.passLength && this.passNumber && this.passSmallCase && this.passUpperCase)) {
          this.errorPassword = 'Не соответствует требованиям сложности пароля';
          return false;
        } else {
          this.errorPassword = '';
        }
        return true;
      },
      sendAction() {
        let canSend = 0;
        //если забубенить в один if проверка стопорнется на первом косяке, последующие не покажуться пользователю
        if (!this.checkNewPass()) {
          canSend += 1;
        }
        if (!this.checkPassConfirm()) {
          canSend += 1;
        }
        if (!this.checkConfirmationCode()) {
          canSend += 1;
        }
        if (canSend > 0) {
          return false;
        }
        this.isLoading = true;
        this.$external.universalRequest({
          externalType: 'zita',
          action:'ConfirmationCode',
          UserID: this.UserID,
          ConfirmationCode: this.ConfirmationCode,
          password: this.pass1,
        })
        .then((res) => {
          this.$store.commit('setSessionKey', res.SessionKey);
          localStorage.setItem('defPass',false);
          if (localStorage.getItem('redirect') != undefined) {
            this.$router.push(localStorage.getItem('redirect'));
          } else {
            this.$router.push('/');
          }
        })
        .catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        }).finally(()=>{
          this.isLoading = false;
        });
      },
      countdownAction() {
        let saveCount = parseInt(localStorage.getItem('countdown'), 10);
        if (saveCount > 0) {
          setTimeout(() => {
            this.smsCountdown = saveCount -= 1;
            localStorage.setItem('countdown', saveCount);
            this.countdownAction();
          }, 1000);
        } else {
          this.smsCountdown = 0;
        }
      },
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    computed:{
      ...mapState({
        UserID: state => state.auth.UserID,
        isNsb: state => state.auth.isNsb,

      }),
      passLength() {
        return this.pass1.length > 7;
      },
      passNumber() {
        return /\d/.test(this.pass1);
      },
      passSmallCase() {
        return /[a-zа-я]/.test(this.pass1);
      },
      passUpperCase() {
        return /[A-ZА-Я]/.test(this.pass1);
      }
    },
    mounted() {
      this.responseError = '';
      this.isLoading = false;
      this.$myApi.checkVersion();
      this.countdownAction();
    }
  }
</script>

<style scoped>

</style>
